<template>
  <v-card
    id="payment-form"
    min-height="350"
  >
    <!-- actions -->
    <v-fab-transition>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            color="secondary"
            dark
            absolute
            small
            top
            right
            fab
            class="mb-4 me-3"
            v-on="on"
            @click="goBack"
          >
            <v-icon>
              {{ icons.mdiTableArrowLeft }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ t("tooltip.back_table") }}</span>
      </v-tooltip>
    </v-fab-transition>

    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
      color="secondary"
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
        color="secondary"
        :disabled="tab.disabled"
      >
        <v-icon
          v-if="!tab.hide"
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <payment-tab-general
          :data-params="paymentData"
          :tab="tab"
          :option="option"
          :payment-methods="computedPaymentMethodsOptions"
          :groups="computedGroupsOptions"
          @changeTab="changeTab"
          @save="onSave"
          @updatePaymentMethots="fetchPaymentMethod"
        ></payment-tab-general>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-cycle */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
} from '@mdi/js'
import { onMounted, ref, computed, watch } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success } from '@core/utils/toasted'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@core/utils/useSelectOptions'
import { createPayment, getPaymentMethodsByGroupMin, getPaymentMethodsMin, getGroupsActive } from '@api'

// componets
import PaymentTabGeneral from './PaymentTabGeneral.vue'

export default {
  components: {
    PaymentTabGeneral,
  },
  setup() {
    const { t } = useUtils()
    const { route, router } = useRouter()
    const { userData } = useCryptoJs()
    const { configOrganization } = useSelectOptions()

    const tab = ref(0)
    const option = ref(0)
    const paymentId = ref(null)
    const pageData = ref()
    const filterData = ref()
    const timerSearch = ref(null)
    const paymentData = ref({})
    const postData = ref({
      amount: null,
      payment_method_id: null,
      note: null,
      transactions: null,
      username: userData.value.username,
      group_id: configOrganization.value,
    })
    const paymentMethodsOptions = ref([])
    const computedPaymentMethodsOptions = computed(() => paymentMethodsOptions.value)
    const groupsOptions = ref([])
    const computedGroupsOptions = computed(() => groupsOptions.value)

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
    ])

    const fetchPaymentMethod = async id => {
      let response = null
      if (id === -1) {
        paymentMethodsOptions.value = []
      } else if (id && id > -1) {
        response = await getPaymentMethodsByGroupMin(id)
      } else if (userData.value && userData.value.role !== 'A') {
        response = await getPaymentMethodsByGroupMin(userData.value.group_id)
      } else {
        response = await getPaymentMethodsMin()
      }

      if (response) paymentMethodsOptions.value = response.data
    }

    const fetchGroups = async () => {
      const groups = await getGroupsActive()
      groupsOptions.value = groups.data
    }

    const changeTab = (tabNumber, validForm) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const onSave = async data => {
      let resp = null
      if (option.value === 1) {
        postData.value = Object.assign(postData.value, {
          amount: data.amount,
          payment_method_id: data.payment_method_id,
          note: data.note,
          transactions: null,
          username: userData.value.username,
          group_id: configOrganization.value,
        })

        resp = await createPayment(postData.value)
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      paymentData.value = {
        amount: null,
        payment_method_id: null,
        note: null,
        transactions: null,
        username: userData.value.username,
        group_id: configOrganization.value,
      }
    }

    const goBack = () => {
      router.push({
        name: 'views-payment-list',
        params: {
          pageParams: pageData.value,
          filterParams: filterData.value,
        },
      })
    }

    watch(configOrganization, async () => {
      clearTimeout(timerSearch.value)
      timerSearch.value = setTimeout(async () => {
        paymentData.value.group_id = null
        await fetchPaymentMethod(configOrganization.value)
      }, 500)
    })

    onMounted(async () => {
      option.value = route.value.params.option
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams

      if (option.value !== 1) {
        paymentId.value = route.value.params.id
        paymentData.value = route.value.params.dataParams
        if (!paymentData.value) router.go(-1)
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }

      if (option.value !== 1) {
        tabs.value.splice(1, 1)
      }

      await fetchGroups()
      await fetchPaymentMethod(configOrganization.value)
    })

    return {
      // data
      tab,
      tabs,
      option,
      paymentId,
      paymentData,
      postData,
      pageData,
      filterData,
      configOrganization,

      // computed
      computedPaymentMethodsOptions,
      computedGroupsOptions,

      // methods
      changeTab,
      onSave,
      cancelAction,
      goBack,
      fetchPaymentMethod,
      fetchGroups,

      // i18n
      t,

      // icons
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
      },
    }
  },
}
</script>
